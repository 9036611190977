<template lang="pug">
  div
    el-card(body-style="padding:0")
      div(slot="header" v-if="permitted")
        div
          el-form(inline)
            el-form-item
              el-cascader(
                v-model="client"
                :props="{ value: 'id', label: 'name', multiple: true }"
                placeholder="Müşteri seçiniz"
                :options="clients"
                collapse-tags
                filterable
                clearable
                style="width: 300px"
                :filter-method="filterClients"
                @change="setClient"
              )
            el-form-item
              el-select(
                v-model="filters.sellers"
                multiple
                clearable
                filterable
                collapse-tags
                placeholder="Satıcı seçiniz"
                style="width: 200px"
                default-first-option
                :disabled="reportType === 'grouped'"
              )
                el-option(v-for="c in sellers" :key="c.id" :value="c.id" :label="c.name")
            el-form-item
              el-select(
                v-model="filters.cashier_id"
                clearable
                filterable
                placeholder="Kasier seçiniz"
                :disabled="reportType === 'grouped'"
              )
                el-option(v-for="c in cashiers" :key="c.id" :value="c.id" :label="c.name")
            el-form-item(v-if="['categorized', 'products'].includes(reportType)")
              el-select(
                v-model="filters.categories"
                multiple
                clearable
                filterable
                collapse-tags
                placeholder="Kategori seçiniz"
                style="width: 200px"
                default-first-option
                :disabled="reportType === 'grouped'"
              )
                el-option(v-for="c in categories" :key="c.id" :value="c.id" :label="c.name")
            el-form-item
              el-select(v-model="filters.displayCurrency" placeholder="Para birimi" style="width: 100px" @change="fetch(false)")
                el-option(v-for="c in $store.state.currencies" :key="c" :value="c" :label="c")
            el-form-item
              el-date-picker(
                v-model="filters.sale_date"
                type="date"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                :picker-options="{ firstDayOfWeek: 1 }"
                style="width: 145px"
                @change="filters.period = []"
                clearable
                placeholder="Tarih"
              )
            el-form-item(v-if="reportType === 'products'")
              el-autocomplete(
                v-model="search"
                @select="product_id = $event.id;fetch();"
                @clear="product_id = null;report = []"
                :fetch-suggestions="searchProduct"
                prefix-icon="el-icon-edit"
                suffix-icon="el-icon-search"
                placeholder="Stok kodu veya ürün adını giriniz"
                value-key="name"
                clearable
                style="width: 600px"
                :debounce="200"
                autocomplete="on"
                placement="bottom-end"
                ref="search"
              )
                template(slot-scope="{ item }")
                  span.text-size-small.mr-15.text-muted {{ item.id }}
                  span.text-bold {{ item.name }}

                  span(style="float: right" :class="{ 'text-success': item.available >= 10, 'text-danger': item.available < 10 }")
                    span.text-size-small.text-success.pr-20 {{ item.sell }} {{ item.sell_currency }}
                    i.el-icon-warning.pr-5(v-if="item.available < 10")
                    | {{ item.available }} {{ item.unit.name }}
            el-form-item
              el-date-picker(
                v-model="filters.period"
                type="daterange"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                :picker-options="$store.state.dateranges"
                range-separator="—"
                start-placeholder="Başlangıç"
                end-placeholder="Bitiş"
                style="width: 270px"
                @change="filters.sale_date = null"
                clearable
              )
            el-form-item
              el-button(
                type="primary"
                plain
                @click="fetch(false)"
                :loading="fetching"
                icon="el-icon-tickets"
              ) Getir
            el-form-item
              el-button(type="primary" plain @click="fetch(true)" icon="el-icon-download" :loading="downloading") Excel
            el-form-item(v-if="reportType === 'detailed'")
              el-button(type="danger" plain @click="recalculate" :loading="recalculating" icon="el-icon-refresh-left") Yeniden hesapla
            el-form-item
              el-button(
                type="primary"
                @click="$router.push({ name: 'sale', params: { id: 'new' } })"
                icon="el-icon-plus"
                :disabled="!can('sales.create')"
              ) Yeni satış


      el-tabs(v-model="reportType" type="border-card")
        el-tab-pane(name="detailed" label="Detaylı satışlar")
          el-alert(
            type="error"
            title="Satışları listeleme yetkiniz yok"
            v-if="!can('sales.list')"
            show-icon
            :closable="false"
            center
          )
          el-table(
            :data="report"
            v-loading="fetching"
            @row-dblclick="edit($event.id)"
            size="mini"
            :show-summary="true"
            :summary-method="detailedSummary"
            :row-class-name="saleStatus"
            stripe
            :height="height-234"
            v-if="report.length && can('sales.list')"
          )
            el-table-column(prop="note" label="Not" width="50px" align="center")
              template(v-slot="props")
                el-tooltip(effect="dark" :content="props.row.note" placement="right" v-if="props.row.note != null")
                  el-button(icon="el-icon-chat-line-square" type="danger" style="padding: 3px 11px;" size="medium")
            el-table-column(prop="branch" label="Şube" width="100px")
            el-table-column(prop="id" label="Satış No" width="70px" align="right")
            el-table-column(prop="badge" label="Yaka" width="80px" align="center" sortable v-if="!hasFeature('customers')")
            el-table-column(prop="client_type" label="Satış tipi" width="110px" sortable v-if="!hasFeature('customers')")
            el-table-column(prop="company" label="Acenta/Taksi" width="150px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(prop="client" label="Rehber"  width="150px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(prop="client" label="Müşteri" width="250px" sortable show-overflow-tooltip v-if="hasFeature('customers')")
            el-table-column(prop="sellers" label="Satıcı" width="160px" sortable show-overflow-tooltip)
            el-table-column(prop="nationality" label="Milliyet" width="90px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(label="PAX" align="center" v-if="hasFeature('paxes')")
              el-table-column(prop="initial_pax" label="Gelen" sortable width="95px" align="right" class-name="text-danger")
              el-table-column(prop="pax" label="Giren" sortable width="95px" align="right" class-name="text-success")
              el-table-column(prop="paxAvg" :label="`Ort. ${filters.displayCurrency}`" sortable width="95 px" align="right" class-name="text-success")
                template(v-slot="props"): strong.text-primary {{ props.row.paxAvg }}
            el-table-column(:label="`SATIŞ, ${filters.displayCurrency}`" align="center")
              el-table-column(prop="paid" label="Satış" sortable width="95px" align="right" class-name="text-success")
                template(v-slot="props"): strong.text-success {{ props.row.paid }}
              el-table-column(prop="total" label="E.Toplamı" sortable width="95px" align="right")
              el-table-column(prop="discount" label="İndirim" sortable width="95px" align="right" class-name="text-warning")
                template(v-slot="props"): strong.text-warning {{ props.row.discount }}
            el-table-column(:label="`HAKEDİŞLER, ${filters.displayCurrency}`" align="center" class-name="text-primary")
              el-table-column(
                prop="company_commission"
                label="Acenta/Taksi"
                align="right"
                sortable
                class-name="text-primary"
                width="120px"
                v-if="!hasFeature('customers')"
              )
              el-table-column(
                prop="company_contribution"
                label="Acenta OKP"
                align="right"
                sortable
                class-name="text-primary"
                width="120px"
                v-if="!hasFeature('customers')"
              )
              el-table-column(
                prop="client_commission"
                label="Rehber"
                align="right"
                sortable
                class-name="text-primary"
                width="120px"
                v-if="!hasFeature('customers')"
              )
              el-table-column(prop="sellers_commission" label="Satıcı" align="right" sortable class-name="text-primary" width="110px")
            el-table-column(label="SEÇİLEN SATICILAR" align="center" class-name="text-danger")
              el-table-column(
                prop="selected_sellers_sale"
                label="Satış"
                align="right"
                sortable
                class-name="text-danger"
                width="110px"
              )
              el-table-column(
                prop="selected_sellers_comm"
                label="Hakediş"
                align="right"
                sortable
                class-name="text-danger"
                width="110px"
              )
            el-table-column(prop="currency" label="Para birimi" align="center" width="100px")
            el-table-column(prop="created_at" label="Satış tarihi" sortable align="center" width="140px")
              template(v-slot="props") {{ props.row.created_at }}
            el-table-column(prop="entered_at" align="center" label="Giriş - Çıkış" sortable width="120px" v-if="!hasFeature('customers')")
              template(v-slot="props")
                span.text-success {{ props.row.entered_at }}
                span.el-icon-d-arrow-right
                span.text-danger {{ props.row.leaved_at }}
            el-table-column(prop="id" label="İşlem" align="center" width="80px")
              template(v-slot="props")
                el-button-group
                  el-button.micro(plain @click="edit(props.row.id)" v-if="can('sales.edit')")
                    span.el-icon-edit
                  el-button.micro(plain @click="remove(props.row.id)" v-if="can('sales.remove')")
                    span.el-icon-delete.text-danger
        el-tab-pane(name="grouped" label="Grup satışları" v-if="!hasFeature('customers')")
          el-alert(
            type="error"
            title="Grup satışları listeleme yetkiniz yok"
            v-if="!can('reports.sales.grouped')"
            show-icon
            :closable="false"
            center
          )
          el-table(
            v-if="report.length > 0 && can('reports.sales.grouped')"
            :data="report"
            size="mini"
            v-loading="fetching"
            show-summary
            :summary-method="groupedSummary"
            stripe
            :height="height-186"
          )
            el-table-column(prop="badge" label="Yaka No" width="90px" align="center" sortable v-if="!hasFeature('customers')")
            el-table-column(prop="branch" label="Şube" width="100px")
            el-table-column(prop="client_type" label="Satış tipi" width="100px" sortable v-if="!hasFeature('customers')")
            el-table-column(prop="company" label="Acenta/Taksi" width="140px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(prop="client" label="Rehber" width="140px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(prop="client" label="Müşteri" sortable show-overflow-tooltip v-if="hasFeature('customers')" width="250px")
            el-table-column(prop="sellers" label="Satıcı" width="180px" sortable show-overflow-tooltip)
            el-table-column(prop="nationality" label="Milliyet" width="90px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(label="PAX" align="center" v-if="!hasFeature('customers')")
              el-table-column(prop="initial_pax" label="Gelen" sortable width="90px" align="center" class-name="text-danger")
              el-table-column(prop="pax" label="Giren" sortable width="90px" align="center" class-name="text-success")
            el-table-column(:label="`SATIŞ, ${filters.displayCurrency}`" align="center")
              el-table-column(prop="paid" label="Satış" sortable width="90px" align="right" class-name="text-success")
                template(v-slot="props"): strong {{ props.row.paid }}
              el-table-column(prop="total" label="E. toplamı" sortable width="95px" align="right")
                template(v-slot="props"): strong {{ props.row.total }}
              el-table-column(prop="discount" label="İndirim" sortable width="95px" align="right" class-name="text-warning")
                template(v-slot="props"): strong {{ props.row.discount }}
            el-table-column(:label="`HAKEDİŞLER, ${filters.displayCurrency}`" align="center" class-name="text-primary")
              el-table-column(prop="company_commission" label="Acenta/Taksi" align="right" sortable class-name="text-primary" width="120px" v-if="!hasFeature('customers')")
              el-table-column(prop="company_contribution" label="Acenta OKP" align="right" sortable class-name="text-primary" width="120px" v-if="!hasFeature('customers')")
              el-table-column(prop="client_commission" label="Rehber" align="right" sortable class-name="text-primary" width="120px" v-if="!hasFeature('customers')")
              el-table-column(prop="sellers_commission" label="Satıcı" align="right" sortable class-name="text-primary" width="120px")
              el-table-column(prop="driver_payment" label="Şoför" align="right" sortable class-name="text-primary" width="120px" v-if="!hasFeature('customers')")
            el-table-column(prop="currency" label="Para birimi" align="center" width="100px")
            el-table-column(prop="created_at" label="Satış tarihi" sortable align="center" width="140px")
            el-table-column(prop="entered_at" align="center" label="Giriş - Çıkış" sortable width="100px" v-if="!hasFeature('customers')")
              template(v-slot="props")
                span.text-success {{ props.row.entered_at }}
                span.el-icon-d-arrow-right
                span.text-danger {{ props.row.leaved_at }}
            el-table-column
        el-tab-pane(name="categorized" label="Ürün grubu satışları")
          el-alert(
            type="error"
            title="Ürün grubu satışları listeleme yetkiniz yok"
            v-if="!can('reports.sales.categorized')"
            show-icon
            :closable="false"
            center
          )
          el-table(
            v-if="report.length > 0 && can('reports.sales.categorized')"
            :data="report"
            size="mini"
            v-loading="fetching"
            show-summary
            :summary-method="categorizedSummary"
            stripe
            :height="height-186"
            :span-method="categorySpanMethod"
          )
            el-table-column(prop="badge" label="Yaka No" width="90px" align="center" sortable v-if="!hasFeature('customers')")
            el-table-column(prop="client_type" label="Satış tipi" width="90px" sortable v-if="!hasFeature('customers')")
            el-table-column(prop="company" label="Acenta/Taksi" width="130px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(prop="client" label="Rehber" width="130px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(prop="nationality" label="Milliyet" width="90px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(prop="client" label="Müşteri" width="220px" sortable show-overflow-tooltip v-if="hasFeature('customers')")
            el-table-column(label="PAX" align="center" v-if="!hasFeature('customers')")
              el-table-column(prop="initial_pax" label="Gelen" sortable width="90px" align="center" class-name="text-danger")
              el-table-column(prop="pax" label="Giren" sortable width="90px" align="center" class-name="text-success")
            el-table-column(:label="`SATIŞ, ${filters.displayCurrency}`" align="center" class-name="bg-gray")

              el-table-column(prop="paid" label="Satış" sortable width="90px" align="right" class-name="text-success bg-gray")
                template(v-slot="props"): strong {{ props.row.paid }}
              el-table-column(prop="total" label="E. toplamı" sortable width="95px" align="right" class-name="bg-gray")
                template(v-slot="props"): strong {{ props.row.total }}
              el-table-column(prop="discount" label="İndirim" sortable width="95px" align="right" class-name="text-warning bg-gray")
                template(v-slot="props"): strong {{ props.row.discount }}
            el-table-column(prop="category" label="Ürün grubu" width="120px" sortable show-overflow-tooltip)
            el-table-column(
              :label="`HAKEDİŞLER, ${filters.displayCurrency}`"
              align="center"
              class-name="text-primary bg-lightblue"
              v-if="!hasFeature('customers')"
            )
              el-table-column(
                v-if="!hasFeature('customers')"
                prop="company_comm_rate"
                label="Acenta/Taksi %"
                align="right"
                sortable
                class-name="text-danger bg-lightblue"
                width="130px"
              )
              el-table-column(
                v-if="!hasFeature('customers')"
                prop="company_commission"
                label="Acenta/Taksi"
                align="right"
                sortable
                class-name="text-primary bg-lightblue"
                width="120px"
              )
              el-table-column(
                v-if="!hasFeature('customers')"
                prop="company_contr_rate"
                label="Acenta OKP %"
                align="right"
                sortable
                class-name="text-danger bg-lightblue"
                width="120px"
              )
              el-table-column(
                v-if="!hasFeature('customers')"
                prop="company_contribution"
                label="Acenta OKP"
                align="right"
                sortable
                class-name="text-primary bg-lightblue"
                width="120px"
              )
              el-table-column(
                v-if="!hasFeature('customers')"
                prop="client_comm_rate"
                label="Rehber %"
                align="right"
                sortable
                class-name="text-danger bg-lightblue"
                width="110px"
              )
              el-table-column(
                v-if="!hasFeature('customers')"
                prop="client_commission"
                label="Rehber"
                align="right"
                sortable
                class-name="text-primary bg-lightblue"
                width="110px"
              )
            el-table-column(prop="currency" label="Para birimi" align="center" width="100px")
            el-table-column(
              prop="created_at"
              label="Satış tarihi"
              sortable
              align="center"
              width="140px"
            )
            el-table-column(
              v-if="!hasFeature('customers')"
              prop="entered_at"
              align="center"
              label="Giriş - Çıkış"
              sortable
              width="120px"
            )
              template(v-slot="props")
                span.text-success {{ props.row.entered_at }}
                span.el-icon-d-arrow-right
                span.text-danger {{ props.row.leaved_at }}
            el-table-column
        el-tab-pane(name="products" label="Ürün satışları")
          el-alert(
            type="error"
            title="Ürün satışları listeleme yetkiniz yok"
            v-if="!can('reports.sales.products')"
            show-icon
            :closable="false"
            center
          )
          el-table(
            v-if="report.length > 0 && can('reports.sales.products')"
            :data="report"
            size="mini"
            v-loading="fetching"
            show-summary
            :summary-method="productsSummary"
            stripe
            :height="height-232"
            @row-dblclick="edit($event.sale_id)"
          )
            el-table-column(prop="branch" label="Şube" width="90px" align="center" sortable)
            el-table-column(prop="sale_id" label="Satış No" width="90px" align="center" sortable)
            el-table-column(prop="badge" label="Yaka No" width="90px" align="center" sortable v-if="!hasFeature('customers')")
            el-table-column(prop="client_type" label="Satış tipi" width="90px" sortable v-if="!hasFeature('customers')")
            el-table-column(prop="company" label="Acenta/Taksi" width="130px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(prop="client" label="Rehber" width="130px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(prop="client" label="Müşteri" width="200px" sortable show-overflow-tooltip v-if="hasFeature('customers')")
            el-table-column(prop="nationality" label="Milliyet" width="90px" sortable show-overflow-tooltip v-if="!hasFeature('customers')")
            el-table-column(prop="category" label="Ürün grubu" width="120px" sortable show-overflow-tooltip)
            el-table-column(prop="product_id" label="Stok kodu" sortable show-overflow-tooltip width="100px" align="center")
            el-table-column(prop="product" label="Ürün adı" sortable show-overflow-tooltip width="250px")
            el-table-column(prop="qty" label="Ürün miktarı" width="120px" sortable show-overflow-tooltip align="right")
              template(v-slot="props")
                strong {{ props.row.qty }}
                span.pl-10 {{ props.row.unit }}

            el-table-column(label="PAX" align="center" v-if="!hasFeature('customers')")
              el-table-column(prop="initial_pax" label="Gelen" sortable width="90px" align="center" class-name="text-danger")
              el-table-column(prop="pax" label="Giren" sortable width="90px" align="center" class-name="text-success")
            el-table-column(:label="`SATIŞ, ${filters.displayCurrency}`" align="center" class-name="bg-gray")
              el-table-column(prop="paid" label="Satış" sortable width="90px" align="right" class-name="text-success bg-gray")
                template(v-slot="props"): strong {{ props.row.paid }}
              el-table-column(prop="total" label="E. toplamı" sortable width="95px" align="right" class-name="bg-gray")
                template(v-slot="props"): strong {{ props.row.total }}
              el-table-column(prop="discount" label="İndirim" sortable width="95px" align="right" class-name="text-warning bg-gray")
                template(v-slot="props"): strong {{ props.row.discount }}
            el-table-column(prop="currency" label="Para birimi" align="center" width="100px")
            el-table-column(prop="created_at" label="Satış tarihi" sortable align="center" width="140px")
              template(v-slot="props")
                span {{ props.row.created_at|dayjs('DD.MM.YYYY HH:mm') }}
            el-table-column(prop="entered_at" align="center" label="Giriş - Çıkış" sortable width="120px" v-if="!hasFeature('customers')")
              template(v-slot="props")
                span.text-success {{ props.row.entered_at }}
                span.el-icon-d-arrow-right
                span.text-danger {{ props.row.leaved_at }}
          van-empty(v-if="!report.length && permitted" description="Kayıt bulunamadı")
</template>
<script>
import saveState from 'vue-save-state'
import collect from 'collect.js'
import User from '@/models/User'
import Sale from '@/models/Sale'
import ProductCategory from '@/models/ProductCategory'

export default {
  name: 'sales',
  mixins: [saveState],
  data(){
    return {
      search: '',
      fetching: false,
      downloading: false,
      recalculating: false,
      clients: [],
      companies: [],
      sellers: [],
      cashiers: [],
      categories: [],
      client: [],
      reportType: 'detailed',
      filters: {
        period: [],
        sale_date: dayjs().format('YYYY-MM-DD'),
        companies: [],
        clients: [],
        client_types: [],
        sellers: [],
        cashier_id: null,
        categories: [],
        displayCurrency: process.env.VUE_APP_DEFAULT_CURRENCY || 'USD',
      },
      product_id: null,
      report: []
    }
  },
  async created() {
    if (this.can('sales.list')) {
      const { data } = await window.axios.get('clientstree');
      this.clients = data;
      this.sellers = await User.whereIn('roles', ['seller', 'admin']).get()
      this.categories = await ProductCategory.get()
      if (this.filters.sale_date || this.filters.period.length > 0){
        await this.fetch()
      }
    }
  },
  methods: {
    getSaveStateConfig() {
      return {
        cacheKey: 'sales',
        saveProperties: [
          'filters',
          'reportType',
          'clients',
          'companies',
          'sellers',
          'categories',
          'client'
        ]
      }
    },
    async fetch(excel = false) {
      if (this.permitted) {
        if (!excel) {
          this.report = []
          this.fetching = true
        } else {
          this.downloading = true
        }
        try {
          let config = {
            method: 'POST',
            data: {
              clients: this.filters.clients,
              excel,
              warehouses: [this.$store.state.branch.id],
              product: this.product_id,
              ...this.filters
            }
          }

          let data = await Sale.config(config)
            .include([
              'branch',
              'entry',
              'entry.nationality',
              'entry.clientType',
              'entry.company',
              'entry.client',
              'sellers',
              'sellers.seller'
            ])
            .whereIn('companies', this.filters.companies)
            .whereIn('client_types', this.filters.client_types)
            .whereIn('sellers', this.filters.sellers)
            .whereIn('categories', this.filters.categories)
            .whereIn('sale_date_between', this.filters.period || [])
            .where('sale_date', this.filters.sale_date)
            .custom('reports/sales/' + this.reportType)
            .get()
          if (!excel) {
            this.report = data
            this.fetching = false
          } else {
            window.location.href = data[0].url
            this.downloading = false
          }
        } catch(res) {
          this.$message.error(res.message)
          this.fetching = false
          this.downloading = false
        }
      }
    },
    categorySpanMethod({ row, column, rowIndex, columnIndex }) {
      /*
      if (columnIndex === 5) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        }
      }
      */
    },
    async recalculate() {
      this.recalculating = true
      await window.axios.post('sales/recalc', { ids: this.report.map(r => r.id) })
      this.recalculating = false
      this.fetch()
    },
    setClient(values) {
      var companies = []
      var clients = []
      var client_types = []
      if(values.length > 0){
        values.forEach(v => {
          client_types.push(v[0])
          if(v.length === 3){
            clients.push(v[2])
            companies.push(v[1])
          }else if(v.length === 2){
            clients.push(v[1])
          }
        })
      }
      this.filters.companies    = [...new Set(companies)]
      this.filters.clients      = [...new Set(clients)]
      this.filters.client_types = [...new Set(client_types)]
    },
    filterClients(node, keyword) {
      return node.text.toLowerCase().includes(keyword)
    },
    edit(id) {
      if(this.can('sales.edit')) {
        this.$router.push({ name: 'sale', params: { id } })
      }
    },
    async remove(id) {
      await this.$confirm(
        'Satışın silinmesini onaylıyor musunuz?',
        'Uyarı',
        {
          confirmButtonText: 'Evet',
          cancelButtonText: 'Vazgeç',
          type: 'warning',
          center: true
        }
      )
      try {
        let { data } = await window.axios.delete('/sales/' + id)
        this.fetch(false)
        if(data.deleted){
          this.$message({
            type: 'success',
            message: 'Satış silinmiştir',
            center: true,
            closable: true
          })
        }
      } catch(e) {
        this.$message({
          type: 'error',
          message: 'Satış silinemedi',
          center: true,
          closable: false
        })
      }
    },
    saleStatus({row, rowIndex}) {
      if (row.deleted_at != null) {
        return 'bg-danger';
      }
      return '';
    },
    async searchProduct(keyword, callback) {
      let { data } = await window.axios.post('/products/search', { keyword })
      if(data.length > 0) {
        callback(data)
      } else {
        callback([])
      }
    },
    detailedSummary({ columns, data }) {
      data = collect(data)
      let initialPax = data.unique('entry_id').sum('initial_pax')
      let pax = data.unique('entry_id').sum('pax')

      let paid = data.sum('paid').toFixed(2)

      let paxAvg = (data.sum('paid')/pax).toFixed(2)

      let total = data.sum('total').toFixed(2)

      let discount = data.sum('discount').toFixed(2)

      let companyComm = data.sum('company_commission').toFixed(2)

      let companyContrib = data.sum('company_contribution').toFixed(2)

      let clientComm = data.sum('client_commission').toFixed(2)

      let sellerComm = data.sum('sellers_commission').toFixed(2)

      let selectedSellersSale = data.sum('selected_sellers_sale').toFixed(2)

      let selectedSellersComm = data.sum('selected_sellers_comm').toFixed(2)

      if (this.hasFeature('customers')) {
        return [
          '',
          '',
          '',
          '',
          'TOPLAMLAR',
          paid,
          total,
          discount,
          sellerComm,
          selectedSellersSale,
          selectedSellersComm,
          this.filters.displayCurrency
        ]
      } else {
        return [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'TOPLAMLAR',
          initialPax + ' pax',
          pax + ' pax',
          paxAvg,
          paid,
          total,
          discount,
          companyComm,
          companyContrib,
          clientComm,
          sellerComm,
          selectedSellersSale,
          selectedSellersComm,
          this.filters.displayCurrency
        ]
      }
    },
    groupedSummary({ columns, data }) {
      data = collect(data)

      let initialPax = data.sum('initial_pax')

      let pax = data.sum('pax')

      let paid = data.sum('paid').toFixed(2)

      let total = data.sum('total').toFixed(2)

      let discount = data.sum('discount').toFixed(2)

      let companyComm = data.sum('company_commission').toFixed(2)

      let companyContrib = data.sum('company_contribution').toFixed(2)

      let clientComm = data.sum('client_commission').toFixed(2)

      let sellerComm = data.sum('sellers_commission').toFixed(2)

      let driverContrib = data.sum('driver_payment').toFixed(2)

      if (this.hasFeature('customers')) {
        return ['', 'TOPLAMLAR', paid, total, discount, sellerComm, this.filters.displayCurrency]
      } else {
        return ['', '', '', '', '', '', 'TOPLAMLAR', initialPax + ' pax', pax + ' pax', paid, total, discount, companyComm, companyContrib, clientComm, sellerComm, driverContrib, this.filters.displayCurrency]
      }
    },
    categorizedSummary({ columns, data }) {
      data = collect(data)

      let initialPax = data.sum('initial_pax')

      let pax = data.sum('pax')

      let paid = data.sum('paid').toFixed(2)

      let total = data.sum('total').toFixed(2)

      let discount = data.sum('discount').toFixed(2)

      let companyComm = data.sum('company_commission').toFixed(2)

      let companyContrib = data.sum('company_contribution').toFixed(2)

      let clientComm = data.sum('client_commission').toFixed(2)

      if (this.hasFeature('customers')) {
        return [
          'TOPLAMLAR',
          paid,
          total,
          discount,
          this.filters.displayCurrency
        ]
      } else {
        return [
          '',
          '',
          '',
          '',
          'TOPLAMLAR',
          initialPax + ' pax',
          pax + ' pax',
          paid,
          total,
          discount,
          '',
          '',
          companyComm,
          '',
          companyContrib,
          '',
          clientComm,
          this.filters.displayCurrency,
          '',
          ''
        ]
      }
    },
    productsSummary({ columns, data }) {
      data = collect(data)

      let initialPax = data.sum('initial_pax')

      let pax = data.sum('pax')

      let paid = data.sum('paid').toFixed(2)

      let total = data.sum('total').toFixed(2)

      let discount = data.sum('discount').toFixed(2)

      if (this.hasFeature('customers')) {
        return [
          '',
          '',
          '',
          'TOPLAMLAR',
          paid,
          total,
          discount,
          this.filters.displayCurrency
        ]
      } else {
        return [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'TOPLAMLAR',
          initialPax + ' pax',
          pax + ' pax',
          paid,
          total,
          discount,
          this.filters.displayCurrency,
          '',
          '',
          '',
          ''
        ]
      }
    }
  },
  computed: {
    height: function() {
      return this.$store.state.windowHeight
    },
    permitted() {
      return (
        this.can('sales.list') ||
        this.can('reports.sales.categorized') ||
        this.can('reports.sales.grouped') ||
        this.can('reports.sales.products')
      )
    }
  },
  watch: {
    reportType: {
      handler() {
        this.fetch(false)
      }
    }
  }
}
</script>
<style>
  .bg-lightblue{
    background-color: #ecf5ff!important;
  }
  .bg-gray{
    background-color: #f0f0f0!important;
  }
  .bg-danger{
    background-color: red!important;
  }
  tr.bg-danger * {
    color: #fff !important;
  }
</style>
