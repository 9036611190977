<template lang="pug">
  div
    el-form.no-padding-top
      el-form-item
        el-autocomplete(
          v-model="search"
          @select="$emit('select', $event)"
          @clear="$emit('deselect', null)"
          :fetch-suggestions="searchCustomer"
          prefix-icon="el-icon-edit"
          suffix-icon="el-icon-search"
          placeholder="Müşteri adını giriniz"
          value-key="name"
          clearable
          style="width: 100%"
          :debounce="200"
          autocomplete="on"
          placement="bottom-end"
          ref="searchCustomer"
          autofocus
        )
          span(slot="prepend") Müşteri
          el-button(
            slot="append"
            icon="el-icon-plus"
            @click="create"
          ) Müşteri oluştur
          template(slot-scope="{ item }")
            span.text-bold {{ item.name }}
    el-drawer(
      :visible.sync="modal"
      :title="customer.name"
      size="400px"
    )
      el-form.p-20(ref="form" :model="customer" label-position="top")
        el-form-item(label="Müşteri adı")
          el-input(v-model="customer.name")
        el-form-item(label="Telefon numarası")
          el-input(v-model="customer.phone")
      center.pt-20
        el-button(@click="save" :loading="saving" icon="el-icon-check") Kaydet
</template>
<script>
import User from '@/models/User'

export default {
  name: 'CustomerSelector',
  props: ['value'],
  data() {
    return {
      search: '',
      modal: false,
      saving: false,
      customer: new User({
        name: '',
        phone: '',
        roles: ['customer'],
        active: true
      })
    }
  },
  created() {
    this.search = this.value || ''
  },
  methods: {
    async searchCustomer(keyword, callback) {
      const customers = await User
        .where('name', keyword)
        .whereIn('roles', ['customer'])
        .get()
      if(customers.length > 0) {
        callback(customers)
      } else {
        callback([])
      }
    },
    create() {
      this.modal = true
      this.customer = new User({
        name: '',
        phone: '',
        roles: ['customer'],
        active: true
      })
    },
    async save() {
      try {
        this.saving = true
        this.customer = await this.customer.save()
        this.saving = false
        this.modal = false
        this.$emit('select', this.customer)
      } catch(e) {
        this.$message.error('KAYIT OLUŞTURULAMADI')
        this.modal = false
        this.saving = false
      }
    }
  }
}
</script>