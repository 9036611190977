<template lang="pug">
  div
    el-alert(
      type="error"
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!permitted"
      show-icon
      :closable="false"
      center
    )
    el-card(body-style="padding:0" v-if="permitted")
      div(slot="header")
        el-row(:gutter="10")
          el-form(inline @submit.prevent.native="add")
            el-col(:xl="12" :lg="12" :md="12")
              product-selector(
                @add="add"
                :warehouse="$store.state.branch.id"
                :show-branch="true"
                :branch="sale.hasOwnProperty('branch') && sale.branch ? sale.branch.name : $store.state.branch.name"
              )
            el-col(:xl="4" :lg="4" :md="8")
              el-form-item
                el-date-picker(
                  v-model="sale.created_at"
                  type="datetime"
                  placeholder="Satış tarihi"
                  :picker-options="{ firstDayOfWeek: 1 }"
                  format="dd.MM.yyyy HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%"
                )
            el-col(:xl="8" :lg="8" :md="8")
              el-form-item
                el-button(type="success" icon="el-icon-check" :loading="saving" @click="save" :disabled="!saveable") KAYDET
                el-button(type="danger" plain icon="el-icon-delete" @click="emptyCart" v-if="sale.items.length > 0") TEMİZLE
                el-button(type="danger" plain icon="el-icon-delete" @click="removeSale" v-if="sale.hasOwnProperty('id') && can('sales.remove')") SİL
      el-alert(type="error" title="SEPETTE ÜRÜN BULUNMAMAKTADIR" center :closable="false" v-if="sale.items.length === 0" show-icon)
      table#cart.table.table-xss.table-hover.border-bottom.border-right.table-bordered.no-margin-bottom(v-if="sale.items.length > 0")
        thead
          tr
            th.text-right(style="width: 28px;") #
            th(style="width: 130px;") STOK KODU
            th ÜRÜN
            th(style="width: 110px;") MİKTAR
            th(style="width: 140px;") ETİKET FİYATI
            th(style="width: 140px;") SATIŞ FİYATI
            th(style="width: 140px;") ETİKET TOPLAMI
            th(style="width: 140px;") SATIŞ TOPLAMI
            th(style="width: 34px;") SİL
        tbody
          tr(v-for="(item, i) in sale.items" :key="i")
            td.pr-5.text-right.text-size-mini {{ i+1 }}
            td.text-center: el-link(@click="showedProduct = item; showProduct = true;"): strong.text-primary {{ item.product_id }}
            td.text-left: strong.pl-10 {{ item.product.name }}
            td
              el-row(:gutter="0")
                el-col(:span="16")
                  el-input.qty(
                    type="number"
                    v-model="item.qty"
                    :min="0"
                    :max="item.available"
                    @input="calcTotal(item)"
                  )
                el-col(:span="8")
                  el-input(:value="item.product.unit.name" readonly)
            td {{ item.product.sell }} {{ item.currency }}
            td {{ item.price }} {{ item.currency }}
            td {{ item.total }} {{ item.currency }}
            td {{ item.paid }} {{ item.currency }}
            td.text-center
              el-button.text-danger.pl-10.pr-10(
                type="text"
                icon="el-icon-delete"
                size="medium"
                @click="remove(i)"
              )
          tr
            td(:colspan="10")
              center#sale-note.text-danger
                el-input(
                  type="text"
                  size="medium"
                  v-model="sale.note"
                  show-word-limit
                  :maxlength="200"
                  placeholder="SATIŞ NOTU"
                  clearable
                )
      center(style="background-color: #efe;")
        el-form(inline label-position="top" v-if="sale.items.length > 0")
          el-form-item(label="İNDİRİM TOPLAMI")
            el-input(:value="sale.discount" type="number" style="width: 180px" readonly)
              template(slot="append") {{ $store.state.currency }}
          el-form-item(label="İNDİRİM ORANI")
            el-input(:value="sale.discountRate" type="number" style="width: 180px" readonly)
              template(slot="append") %
          el-form-item(label="ETİKET TOPLAMI")
            el-input(:value="sale.total" type="number" style="width: 180px" readonly)
              template(slot="append") {{ $store.state.currency }}
          el-form-item
            template(slot="label")
              span.text-primary SATIŞ TOPLAMI
            el-input.input-success(
              v-model="sale.paid"
              @input="calcDiscounts"
              type="number"
              :min="0"
              style="width: 180px"
            )
                template(slot="append")
                  el-select(v-model="sale.currency" filterable style="width: 80px" default-first-option)
                    el-option(v-for="c in $store.state.currencies" :key="c" :value="c" :label="c")
          el-form-item#payments
            template(slot="label")
              span.text-primary ÖDEME
              span.text-danger(v-if="paymentLeft > 0") &nbsp;/ KALAN {{ `${paymentLeft.toFixed(2)} ${sale.currency}` }}
              span.text-success(v-if="paymentLeft < 0") &nbsp;/ {{ `${paymentLeft.toFixed(2)} ${sale.currency}` }} FAZLA ALINACAK
            template(v-for="(payment, i) in sale.payments")
              el-input(v-model="payment.amount" :key="i" type="number" :min="0" style="width: 320px" @input="changePaymentAmount(i, payment)")
                template(slot="append")
                  el-select(v-model="payment.currency" @change="changePaymentCurrency(i, payment)" filterable style="width: 80px" default-first-option)
                    el-option(v-for="c in $store.state.currencies" :key="c" :value="c" :label="c")
                template(slot="prepend")
                  el-select.payment-type(v-model="payment.type_id" filterable style="width: 130px" default-first-option)
                    el-option(v-for="p in paymentTypes" :key="p.id" :value="p.id" :label="p.name")
              el-button.text-danger(icon="el-icon-delete" type="text" :disabled="i === 0 && payment.amount > 0 " @click="removePayment(i)")
              br/
            el-button(@click="addPayment" icon="el-icon-plus" type="text" v-if="hasFeature('multiple.payment')") EKLE
          el-form-item(label="_" style="color: #fff;")
            el-button(type="success" icon="el-icon-check" :loading="saving" @click="save" :disabled="!saveable") SATIŞI KAYDET

    el-divider(v-if="sale.items.length > 0") HAKEDİŞ

    el-row(:gutter="10" v-if="sale.items.length > 0")
      el-col(:span="9")
        el-card(body-style="padding:0")
          div(slot="header")
            customer-selector.pt-10(
              v-if="hasFeature('customers')"
              @select="onCustomerSelect"
              @deselect="onCustomerDeselect"
              v-model="sale.entry.client.name"
            )
            el-form.pt-10(v-if="hasFeature('entries')")
              el-row(:gutter="10")
                el-col(:span="24")
                  el-form-item
                    el-select(
                      v-model="sale.entry_id"
                      filterable
                      clearable
                      placeholder="YAKA NO"
                      @clear="resetEntry"
                      default-first-option
                      style="width: 100%"
                    )
                      el-button(
                        type="primary"
                        size="mini"
                        icon="el-icon-refresh"
                        @click="fetchEntries"
                        :loading="fetchingEntries"
                        slot="prefix"
                      )
                      el-option(
                        v-for="e in entries"
                        :key="e.id"
                        :value="e.id"
                        :label="e.label"
                      )
                        span(v-if="e.leaved_at !== null")
                          span.text-danger Çıktı
                          span &nbsp;&bullet;&nbsp;
                        span(v-if="e.leaved_at === null")
                          span.text-success İçeride
                          span &nbsp;&bullet;&nbsp;
                        span {{ e.label }}
          el-row(:gutter="10")
            el-col(:span="24" v-if="sale.entry.company && sale.entry.company.commissions.length > 0 && sale.items.length > 0")
              el-card(shadow="always" body-style="padding: 0")
                el-table(:data="soldCategories" size="mini" show-summary stripe)
                  el-table-column(prop="name" label="MAL GRUBU" width="140px")
                  el-table-column(prop="sale" :label="`SATIŞ, ${$store.state.currency}`" align="right" sortable width="100px")
                  el-table-column(v-if="sale.entry.company" :label="sale.entry.company.name" align="center" class-name="text-success")
                    el-table-column(label="Oran, %" align="right" class-name="text-success")
                    el-table-column(prop="companyEarning" :label="`Hakediş, ${$store.state.currency}`" align="right" class-name="text-success")
                  el-table-column(v-if="sale.entry.client" :label="sale.entry.client.name" align="center" class-name="text-primary")
                    el-table-column(label="Oran, %" align="right" class-name="text-primary")
                    el-table-column(prop="clientEarning" :label="`Hakediş, ${$store.state.currency}`" align="right" class-name="text-primary")

            el-col(v-if="sale.entry.company_id && sale.entry.company && sale.entry.company.commissions.length === 0")
              el-alert(
                type="error"
                center
                show-icon
                :closable="false"
                title="SEÇİLEN ACENTANIN KOMİSYONLARI TANIMLANMAMIŞ"
                v-if="sale.entry.company && sale.entry.company.commissions.length === 0"
              )
        el-alert(
          type="error"
          center
          show-icon
          :closable="false"
          title="KOMİSYON DAĞILIMINI GÖRMEK İÇİN YAKA NO SEÇİNİZ"
          v-if="!sale.entry_id && hasFeature('entries')"
        )
        el-alert(
          type="error"
          center
          show-icon
          :closable="false"
          title="MÜŞTERİ SEÇİNİZ"
          v-if="!sale.entry.client_id && hasFeature('customers')"
        )
      el-col(:span="15")
        el-card(body-style="padding:0")
          div(slot="header")
            el-form.pt-10
              el-form-item
                el-select(
                  v-model="sale.sellerIds"
                  multiple
                  filterable
                  clearable
                  placeholder="SATICILAR"
                  style="width: 100%"
                  @change="setSellers($event)"
                )
                  el-option(
                    v-for="s in sellers"
                    :key="s.id"
                    :value="s.id"
                    :label="s.name"
                  )
          el-card(
            v-if="sale.sellers.length > 0 && sale.items.length > 0"
            shadow="always"
            body-style="padding: 0"
          )
            el-table(
              :data="soldCategories"
              size="mini"
              show-summary
              :summary-method="sellersSummary"
              :fit="false"
              stripe
            )
              el-table-column(prop="name" label="MAL GRUBU" width="140px")
              el-table-column(prop="sale" :label="`SATIŞ, ${$store.state.currency}`" align="right" sortable width="100px" class-name="text-success")
              template(v-for="s in sale.sellers")
                el-table-column(:label="s.seller.name" align="center" class-name="text-primary" :key="s.seller_id")
                  el-table-column(
                    :prop="sellers ? `sellers.${s.seller_id}.ratio` : ''"
                    label="Oran, %"
                    class-name="text-primary"
                    align="center"
                    width="120px"
                  )
                  el-table-column(
                    :prop="sellers ? `sellers.${s.seller_id}.earning` : ''"
                    :label="`Hakediş, ${$store.state.currency}`"
                    class-name="text-primary"
                    align="center"
                    width="120px"
                  )

        el-alert(
          type="error"
          center
          show-icon
          :closable="false"
          title="KOMİSYON DAĞILIMINI GÖRMEK İÇİN SATICI SEÇİNİZ"
          v-if="sale.sellerIds.length === 0"
        )

    el-divider(v-if="permitted && hasFeature('exchange.rates.widget')") KURLAR

    el-card(body-style="padding:0" v-if="permitted && hasFeature('exchange.rates.widget')")
      div(slot="header")
        center(v-if="rates !== null")
          el-form(inline)
            el-form-item
              el-input.mr-5(
                v-model="rateCalcAmount"
                type="number"
                style="width: 220px"
                v-if="!calcSaleRates"
              )
                template(slot="prepend") {{ sale.currency }}
              el-input.mr-5(
                readonly
                :value="sale.paid"
                type="number"
                style="width: 220px"
                v-if="calcSaleRates && sale.paid > 0"
              )
                template(slot="prepend") {{ sale.currency }}
            el-form-item(v-for="currency in $store.state.currencies.filter(c => c !== sale.currency)" :key="currency")
              el-input.mr-5(:value="`${getComputedRate(currency)} ${currency}`" style="width: 135px")
            el-form-item
              el-checkbox(v-model="calcSaleRates" label="Satış toplamını hesapla")
              el-checkbox(v-model="roundRates" label="Yuvarla")
        center(v-else)
          el-alert(title="KURLAR BEKLENİYOR" type="error" show-icon center :closable="false")
    el-dialog(:visible.sync="showStock" title="Stok" width="90%" top="0px" append-to-body)
      stock
    el-drawer(:visible.sync="showProduct")
      center(slot="title") {{ showedProduct.name }}
</template>
<script>
import Sale from '@/models/Sale'
import Entry from '@/models/Entry'
import User from '@/models/User'
import ProductCategory from '@/models/ProductCategory'
import Stock from '@/pages/main/Stock'
import CustomerSelector from '@/components/CustomerSelector'
import ProductSelector from '@/components/ProductSelector'
import collect from 'collect.js'

export default {
  name: 'sale',
  components: {
    ProductSelector,
    Stock,
    CustomerSelector
  },
  data(){
    return {
      searching: false,
      fetching: false,
      fetchingEntries: false,
      saving: false,
      modal: false,
      showStock: false,
      showProduct: false,
      showedProduct: { id: null, name: '' },
      calcSaleRates: false,
      rateCalcAmount: 1,
      roundRates: false,
      sale: new Sale({
        entry_id: null,
        branch_id: null,
        branch: null,
        customer_id: null,
        entry: {
          company: { name: '', commissions: [] },
          client: { name: '', commissions: [] }
        },
        sellers: [],
        sellerIds: [],
        total: 0,
        paid: 0,
        discount: 0,
        discountRate: 0,
        currency: process.env.VUE_APP_DEFAULT_CURRENCY || 'USD',
        items: [],
        payments: [{
          currency: process.env.VUE_APP_DEFAULT_CURRENCY || 'USD',
          amount: 0,
          type_id: 1
        }],
        created_at: window.dayjs().format('YYYY-MM-DD HH:mm:ss')
      }),
      categories: [],
      companies: [],
      sellers: [],
      entries: [],
      rates: null,
      paymentLeft: 0,
      paymentTypes: [
        { id: 1, name: 'Nakit' },
        { id: 2, name: 'Ziraat POS' },
        { id: 3, name: 'İş Bank POS' },
        { id: 4, name: 'Yapi Kredi POS' },
        { id: 5, name: 'Garanti POS' },
      ]
    }
  },
  async created() {
    await this.fetch()
    await this.fetchEntries()
    if(this.$route.params.id === 'new' && this.sale.id != null){
      this.newSale()
    }
    const eventBus = this.$barcodeScanner.init(this.findAndAdd, { eventBus: true })
    if (eventBus) {
      eventBus.$on('start', () => { this.searching = true })
      eventBus.$on('finish', () => { this.searching = false })
    }
    window.addEventListener('paste', (event) => {
      let keyword = (event.clipboardData || window.clipboardData).getData('text')
      this.findAndAdd(keyword)
    })
  },
  mounted() {
    if (this.$route.params.id === 'new' && this.$refs.search) {
      this.$refs.search.focus()
    }
  },
  methods: {
    newSale(){
      this.sale = new Sale({
        entry_id: null,
        branch_id: this.$store.state.branch.id,
        branch: this.$store.state.branch,
        customer_id: null,
        entry: {
          company: { name: '', commissions: [] },
          client: { name: '', commissions: [] }
        },
        sellers: [],
        sellerIds: [],
        total: 0,
        paid: 0,
        discount: 0,
        discountRate: 0,
        currency: process.env.VUE_APP_DEFAULT_CURRENCY || 'USD',
        items: [],
        payments: [{
          currency: process.env.VUE_APP_DEFAULT_CURRENCY || 'USD',
          amount: 0,
          type_id: 1
        }],
        created_at: window.dayjs().format('YYYY-MM-DD HH:mm:ss')
      })
    },
    async fetch(){
      this.sellers = await User.include('commissions', 'commissions.category')
        .whereIn('roles', ['seller', 'admin'])
        .where('active', true)
        .select('id', 'name')
        .get()
      this.categories = await ProductCategory.get()
    },
    async fetchEntries(){
      this.fetchingEntries = true
      this.entries = await Entry.custom('clientstree')
        .params({
          entered: 1,
          branch_id: this.$store.state.branch.id,
          date: dayjs(this.sale.created_at).format('YYYY-MM-DD')
        })
        .get();
      this.fetchingEntries = false
    },
    async fetchSale(id){
      this.sale = await Sale.include([
        'items',
        'items.product',
        'items.product.category',
        'items.product.unit',
        'payments',
        'sellers',
        'sellers.seller',
        'sellers.seller.commissions',
        'sellers.seller.commissions.category',
        'branch',
        'entry',
        'entry.company',
        'entry.company.commissions',
        'entry.company.commissions.category',
        'entry.client',
        'entry.client.commissions',
        'entry.client.commissions.category'
      ]).find(id)
      this.fetch()
      this.calcSaleRates = true
    },
    async removeSale() {
      await this.$confirm('Satış silinmesine onay veriniz', 'Uyarı', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Vazgeç',
        type: 'warning',
        center: true
      })
      try {
        let { data } = await window.axios.delete('/sales/' + this.sale.id)
        if (data.deleted) {
          this.$message({
            type: 'success',
            message: 'Satış silinmiştir',
            center: true,
            closable: true
          })
          this.newSale()
          this.$router.push({ name: 'sale', params: { id: 'new' } })
        }
      } catch(e) {
        this.$message({
          type: 'error',
          message: 'Satış silinemedi',
          center: true,
          closable: false
        })
      }
    },
    add(item) {
      if(item.hasOwnProperty('id')) {
        let found = this.sale.items.find(i => i.product_id === item.id)
        if(found) {
          found.qty++
          this.calcTotal(found)
        } else {
          this.sale.items.push({
            product: item,
            product_id: item.id,
            qty: 1,
            unit_id: item.unit_id,
            price: item.sell,
            total: item.sell,
            paid: item.sell,
            available: item.available,
            unit: item.unit.name,
            discount: 0,
            currency: item.sell_currency
          })
        }
        this.calcSaleTotal()
        this.calcSaleRates = true
      }
    },
    async findAndAdd(keyword) {
      let { data } = await window.axios.post('/products/search', { keyword })
      console.log(data)
      if(data.length > 0) {
        this.add(data[0])
        if(this.$refs.search) {
          this.$refs.search.focus()
        }
      }
    },
    remove(i) {
      this.$confirm('Ürün sepetten silinecektir, onaylıyormusunuz?', '', {
        confirmButtonText: 'Onayla',
        cancelButtonText: 'Vazgeç',
        title: 'Dikkat',
        type: 'warning',
        center: true
      }).then(() => {
        this.sale.items.splice(i, 1)
        this.calcSaleTotal()
        this.calcDiscounts()
      })
    },
    async save() {
      try {
        this.saving = true
        if (this.sale.hasOwnProperty('id')) {
          await window.axios.put(`/sales/${this.sale.id}`, this.sale)
        } else {
          this.sale.branch_id = this.$store.state.branch.id
          await window.axios.post('/sales', this.sale)
        }
        this.saving = false
        this.$message.success('SATIŞ KAYDEDİLDİ')
        this.newSale()
        if(this.$route.params.id === 'new') {
          window.location.reload(true)
        } else {
          this.$router.push({ name: 'sales' })
        }
      } catch(e) {
        this.saving = false
        this.$message.error('Satış kaydedilemedi: ' + e.message)
      }
    },
    emptyCart() {
      this.$confirm('Sepetin temizlenmesini onaylıyormusunuz?', '', {
        confirmButtonText: 'Onayla',
        cancelButtonText: 'Vazgeç',
        title: 'Dikkat',
        type: 'warning',
        center: true
      }).then(() => {
        this.sale.items = [];
      })
    },

    addPayment(){
      this.$nextTick(() => {
        let amount = Math.ceil(this.calcRate(this.paymentLeft, 'TRY'))
        this.sale.payments.push({ currency: 'TRY', amount, type_id: 1 })
      })
    },
    removePayment(i){
      this.sale.payments.splice(i, 1)
    },
    resetPayments(){
      this.sale.payments = [];
      this.sale.payments.splice(0, 1, {
        amount: Math.ceil(this.sale.paid),
        currency: this.sale.currency,
        type_id: 1
      })
    },
    resetEntry() {
      this.sale.entry_id = null;
      this.sale.entry = {
        company: { name: '', commissions: [] },
        client: { name: '', commissions: [] }
      }
    },
    changePaymentCurrency(i, payment) {
      payment.amount = 0;
      this.$nextTick(() => {
        let amount = Math.ceil(this.calcRate(this.paymentLeft, payment.currency))
        this.sale.payments.splice(i, 1, {
          amount,
          currency: payment.currency,
          type_id: payment.type_id
        })
      })
    },
    changePaymentAmount(i, payment){
      this.$nextTick(() => {
        if(typeof this.sale.payments[i+1] !== 'undefined') {
          if(this.paymentLeft > 0) {
            this.changePaymentCurrency(i+1, this.sale.payments[i+1]);
          }
        }
      })
    },
    async fetchEntry(){
      this.sale.entry = await Entry.include([
        'company',
        'company.commissions',
        'company.commissions.category',
        'client',
        'client.commissions',
        'client.commissions.category'
      ]).find(this.sale.entry_id)
    },
    async setSellers(ids){
      this.$set(this.sale, 'sellers', [])
      if(ids.length > 0) {
        ids.forEach(id => {
          let seller = this.sellers.find(s => s.id === id)
          this.sale.sellers.push({ seller_id: seller.id, seller })
        })
      }
    },
    onCustomerSelect(customer) {
      this.sale.entry.client = customer
      this.sale.entry.client_id = customer.id
    },
    onCustomerDeselect(customer) {
      this.sale.entry.client = { name: '', commissions: [] }
      this.sale.entry.client_id = null
    },
    calcRate(amount, currency) {
      return this.rates[currency]*amount
    },
    calcTotal(item) {
      let total = parseFloat((item.product.sell*item.qty).toFixed(2));
      item.price = parseFloat(item.product.sell.toFixed(3))
      item.total = total
      item.paid = total;
      this.calcSaleTotal()
    },
    calcSaleTotal() {
      let total = parseFloat((this.sale.items.reduce((a, b) => a + (b.product.sell*b.qty), 0)).toFixed(2))
      this.sale.total = parseFloat(total)
      //this.sale.paid = parseFloat(total) // old way
      this.sale.paid = Math.ceil(this.sale.total/this.rates[this.$store.state.currency])
      this.sale.discount = 0
      this.sale.discountRate = 0
      this.resetPayments()
    },
    calcDiscounts() {
      this.resetPayments()
      if (this.paidBaseCurrency !== 0) {
        console.log(this.paidBaseCurrency)
        this.sale.discount = parseFloat((this.sale.total-this.paidBaseCurrency).toFixed(2))
        this.sale.discountRate = parseFloat((100-(this.paidBaseCurrency/this.sale.total*100)).toFixed(2))
        this.sale.items.map(i => {
          let percent = parseFloat((i.total/(this.sale.total/100)).toFixed(4))
          let discount = parseFloat((this.sale.discount/100*percent).toFixed(4))
          i.paid = parseFloat((i.total-discount).toFixed(4))
          i.price = parseFloat((i.product.sell-(discount/i.qty)).toFixed(4))
          i.discount = discount
        })
      }
    },
    calcPaymentLeft() {
      if(this.rates !== null && this.sale.payments.length > 0){
        var paymentsTotal = 0.0;
        this.sale.payments.map(p => {
          if(p.amount > 0){
            let rate = p.amount/this.rates[p.currency];
            paymentsTotal += rate;
          }
        })
        this.paymentLeft = this.sale.paid-paymentsTotal
      }
    },
    async fetchRates() {
      let { data } = await axios.get(`/exchangerates/${this.sale.currency}`)
      this.rates = data;
    },
    getComputedRate(currency) {
      let amount = 0
      if(this.calcSaleRates){
        amount = this.sale.paid*this.rates[currency]
      }else{
        amount = this.rateCalcAmount*this.rates[currency]
      }
      if(this.roundRates){
        amount = Math.ceil(amount)
      }
      return amount.toFixed(2)
    },
    sellersSummary({ columns, data }) {

      data = collect(data).collapse()
      //console.log(data)
      let res = ['TOPLAMLAR', data.sum('sale')]
      this.sale.sellers.forEach(s => {

        let earning = data.sum(i => {
          return i.sellers[s.seller_id].earning
        })
        res.push('')
        res.push(earning)
      })

      return res
    }
  },
  computed: {
    paidBaseCurrency() {
      return Math.ceil(this.sale.paid*this.rates[this.$store.state.currency])
    },
    permitted() {
      let permitted = false
      if(this.$route.params.id === 'new' && this.sale.id !== null) {
        permitted = this.can('sales.create')
      }else{
        permitted = this.can('sales.edit')
      }
      return permitted
    },
    saveable: function() {
      return (
        this.sale.items.length > 0 &&
        this.paymentLeft <= 0 &&
        (!this.sale.entry_id || this.sale.entry.client_id) &&
        this.sale.sellers.length > 0 &&
        this.sale.created_at !== null
      )
    },
    customerOrigin: function() {
      switch (this.sale.entry.nationality_id) {
        case 'tr':
          return 'local'
        case 'ar':
          return 'other'
        default:
          return 'foreign'
      }
    },
    rootCategorySales: function() {
      return collect(this.sale.items)
        .map(i => {
          if(i.product.category && i.product.category.parent_id) {
            i.product.category_id = i.product.category.parent_id;
          }
          return i;
        })
        .groupBy('product.category_id')
        .map(i => i.sum('paid'))
        .all();
    },
    soldCategories: function(){
      return this.categories.filter(c => c.id in this.rootCategorySales).map(c => {
        c.sale = this.rootCategorySales[c.id]

        let sellers = this.sale.sellers.map(s => {
          let ratio = s.seller.commissions.find(com => com.category_id === c.id)
          let earning = 0;

          if(ratio) {
            ratio = ratio[this.customerOrigin]
            earning = c.sale > 0 ? parseFloat((c.sale/this.sellersCount/100*ratio).toFixed(2)) : 0
          } else {
            ratio = 0;
          }

          return { id: s.seller.id, name: s.seller.name, ratio, earning, category_id: c.id }
        })

        c.sellers = collect(sellers).keyBy('id').all()

        if(this.sale.entry.company  && this.sale.entry.company.commissions){
          let companyRatio = this.sale.entry.company.commissions.find(com => com.category_id === c.id)
          if(companyRatio){
            companyRatio = companyRatio[this.customerOrigin]
            let companyEarning = c.sale > 0 ? parseFloat((c.sale/100*companyRatio).toFixed(2)) : 0
            c.companyEarning = companyEarning
          }
        }

        if(this.sale.entry.client  && this.sale.entry.client.commissions){
          let entryRatio = this.sale.entry.client.commissions.find(com => com.category_id === c.id)
          if(entryRatio){
            entryRatio = entryRatio[this.customerOrigin]
            let clientEarning = c.sale > 0 ? parseFloat((c.sale/100*entryRatio).toFixed(2)) : 0
            c.clientEarning = clientEarning
          }
        }

        return c
      }).filter(c => c.sale > 0)
    },
    companyContribution: function(){
      let margin = this.sale.entry.company ? this.sale.entry.company.margin : 0;
      return this.sale.paid > 0 && margin > 0 ? Number((this.sale.paid/100*margin).toFixed(2)) : 0;
    },
    calcClientCommissions: function(){
      let commissions = this.sale.entry.client ? this.sale.entry.client.commissions : [];
      if(commissions.length > 0 && Object.keys(this.rootCategorySales).length > 0){
        return commissions.map(c => {
          let sum = this.rootCategorySales[c.category_id] || 0
          let earning = sum > 0 ? parseFloat((sum/100*c[this.customerOrigin]).toFixed(2)) : 0
          c['sale'] = sum
          c['earning'] = earning
          return c
        }).filter(c => c.sale > 0)
      }else{
        return []
      }
    },
    clientEarnings: function(){
      let ttl = this.calcClientCommissions.reduce((a, b) => a + b.earning, 0)
      return parseFloat(ttl.toFixed(2))
    },
    sellersCount: function(){
      return this.sale.sellers.length
    }
  },
  watch: {
    'sale.payments': {
      deep: true,
      immediate: true,
      handler() {
        this.calcPaymentLeft()
      }
    },
    'sale.entry_id': {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.fetchEntry()
        }
      }
    },
    'sale.currency': {
      immediate: true,
      deep: true,
      async handler(val, oldVal) {
        if (this.$store.state.currencies.includes(val) && val !== oldVal) {
          await this.fetchRates()
          this.calcSaleTotal()
          this.calcDiscounts()
        }
      }
    },
    '$route.params.id': {
      immediate: true,
      handler(val){
        if(val && val !== 'new'){
          this.fetchSale(val);
        }else{
          this.newSale()
        }
      }
    }
  }
}
</script>
<style type="text/css">
  #cart tbody tr:last-child {
    border-bottom: 2px solid #ddd;
  }
  #payments .el-input>.el-input__inner{
    height: 38px!important;
  }
  #payments .el-input-group--append .el-input__inner, #payments .el-input-group--prepend .el-input__inner{
    height: 37px!important;
  }
  #payments .el-input:first-child .el-input__inner,
  #payments .el-input:first-child .el-input-group__prepend,
  #payments .el-input:first-child .el-input-group__append {
    border-top-width: 1px !important;

  }
  #payments .el-input__inner, #payments .el-input-group__prepend, #payments .el-input-group__append {
    border-top-width: 0px !important;
  }
  #sale-note input{
    color: red;
    font-size: 20px;
    font-weight: bold;
    background-color: #ffe5e5;
  }
  .qty input{
    color: #093;
    font-size: 16px;
    font-weight: 600;
  }
</style>
